import { Button, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { db } from "../../db";

export default function DeleteInvoice() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    if (invoice == null) {
      db.invoices
        .get(Number(params.invoiceId))
        .then((res) => {
          setInvoice(res);
        })
        .catch((e) => {
          setInvoice({});
          console.log("error", e);
        });
    }
  });

  const onClickDelete = (e) => {
    e.stopPropagation();

    db.invoices
      .where("id")
      .equals(Number(params.invoiceId))
      .delete()
      .then(() => {
        if (
          location.state &&
          location.state.prevPath &&
          location.state.prevPath.startsWith("/invoices/")
        ) {
          navigate("/invoices");
        } else {
          navigate(-1);
        }
      })
      .catch((e) => {
        setInvoice({});
        console.log("error", e);
      });
  };

  return (
    <>
      <Row justify="center">
        <Typography.Title type="danger">
          Delete invoice {params.invoiceId}
        </Typography.Title>
      </Row>
      {invoice && (
        <>
          <Row justify="center">
            <Typography.Text type="danger" style={{ marginBottom: 20 }}>
              Are you sure thay you want to delete invoice number{" "}
              {invoice.number} billed to {invoice.billTo}? This action can not
              be undone!
            </Typography.Text>
          </Row>
          <Row justify="center">
            <Space>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button danger onClick={onClickDelete}>
                Yes
              </Button>
            </Space>
          </Row>
        </>
      )}
    </>
  );
}
