import PropTypes from "prop-types";
import {
  Form,
  Input,
  Button,
  InputNumber,
  Row,
  Typography,
  Col,
  ConfigProvider,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export default function InvoiceDetailsForm({ form, onChangeValue }) {
  const recalculateTotals = () => {
    if (onChangeValue) {
      onChangeValue();
    }
  };

  const handleChangeQuantity = (e, index) => {
    let values = form.getFieldValue("items");
    const item = values[index];
    const amount = Number((e * item.price).toFixed(2));
    const updatedItem = {
      ...item,
      amount: amount,
    };

    values[index] = updatedItem;
    form.setFieldsValue(values);
    recalculateTotals();
  };
  const handleChangePrice = (e, index) => {
    let values = form.getFieldValue("items");
    const item = values[index];
    const amount = Number((item.quantity * e).toFixed(3));
    const updatedItem = {
      ...item,
      amount: amount,
    };

    values[index] = updatedItem;
    form.setFieldsValue(values);
    recalculateTotals();
  };
  const handleChangeAmount = (e, index) => {
    let values = form.getFieldValue("items");
    const item = values[index];
    const price = Number(item.quantity === 0 ? 0 : e / item.quantity).toFixed(
      2
    );
    const amount = Number(item.quantity * price).toFixed(2);
    const updatedItem = {
      ...item,
      price: price,
      amount: amount,
    };

    values[index] = updatedItem;
    form.setFieldsValue(values);
    recalculateTotals();
  };

  return (
    <>
      <Row>
        <Col md={11} style={{ textAlign: "left" }}>
          <Typography.Text>Description</Typography.Text>
        </Col>
        <Col md={4} style={{ textAlign: "right" }}>
          <Typography.Text>Quantity</Typography.Text>
        </Col>
        <Col md={4} style={{ textAlign: "right" }}>
          <Typography.Text>Price</Typography.Text>
        </Col>
        <Col md={4} style={{ textAlign: "right" }}>
          <Typography.Text>Amount</Typography.Text>
        </Col>
        <Col md={1} style={{ textAlign: "center" }}>
          <Typography.Text>Delete</Typography.Text>
        </Col>
      </Row>

      <Form.List name="items">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row key={index} justify="space-around">
                <Col md={11} style={{ textAlign: "left" }}>
                  <Form.Item
                    {...field}
                    name={[field.name, "description"]}
                    fieldKey={[field.fieldKey, "decription"]}
                    rules={[{ required: true, message: "Missing description" }]}
                  >
                    <Input placeholder="Please enter description of product or service" />
                  </Form.Item>
                </Col>

                <Col md={4} style={{ textAlign: "right" }}>
                  <ConfigProvider direction="rtl">
                    <Form.Item
                      {...field}
                      name={[field.name, "quantity"]}
                      fieldKey={[field.fieldKey, "quantity"]}
                      rules={[{ required: true, message: "Missing quantity" }]}
                    >
                      <InputNumber
                        precision={3}
                        controls={false}
                        onChange={(e) => handleChangeQuantity(e, index)}
                        placeholder="Quantity"
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                  <ConfigProvider direction="rtl">
                    <Form.Item
                      {...field}
                      name={[field.name, "price"]}
                      fieldKey={[field.fieldKey, "price"]}
                      rules={[{ required: true, message: "Missing price" }]}                      
                    >
                      <InputNumber
                        precision={2}
                        controls={false}
                        onChange={(e) => handleChangePrice(e, index)}
                        placeholder="Price"
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                  <ConfigProvider direction="rtl">
                    <Form.Item
                      {...field}
                      name={[field.name, "amount"]}
                      fieldKey={[field.fieldKey, "amount"]}
                      rules={[{ required: true, message: "Missing amount" }]}
                    >
                      <InputNumber
                        style={{ textAlign: "right" }}
                        disabled={true}
                        precision={2}
                        controls={false}
                        onChange={(e) => handleChangeAmount(e, index)}
                        placeholder="Amount"
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Col>
                <Col md={1} style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      remove(field.name);
                      recalculateTotals();
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </Col>
              </Row>
            ))}

            <Form.Item>
              <Button
                onClick={() => {
                  add({
                    description: null,
                    quantity: 1,
                    price: 1,
                    amount: 1,
                  });
                  recalculateTotals();
                }}
                block
                icon={<PlusOutlined />}
              >
                Add New Item
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}

InvoiceDetailsForm.propTypes = {
  form: PropTypes.object,
  onChangeValue: PropTypes.func,
};
