import React from "react";
import "./App.css";
import { Layout } from "antd";
import { Route, Routes } from "react-router";
import Invoices from "./pages/invoices/Invoices";
import Header from "./components/Header";
import OpenInvoice from "./pages/invoices/OpenInvoice";
import NewInvoice from "./pages/invoices/NewInvoice";
import EditInvoice from "./pages/invoices/EditInvoice";
import DeleteInvoice from "./pages/invoices/DeleteInvoice";

const { Content } = Layout;

const App = () => (
  <div className="App">
    <Layout>
      <Header />      
      <Content className="content">
        <Routes>
          <Route exact path="/" element={<Invoices />} />
          <Route path="/invoices">
            <Route index element={<Invoices />} />
            <Route path=":invoiceId/open" element={<OpenInvoice />} />
            <Route path=":invoiceId/edit" element={<EditInvoice />} />
            <Route path=":invoiceId/delete" element={<DeleteInvoice />} />            
            <Route path="new" element={<NewInvoice />} />
          </Route>
          {/* <Route path="about" element={<About />} /> */}
        </Routes>
        {/* <Outlet /> */}
      </Content>
    </Layout>
  </div>
);

export default App;
