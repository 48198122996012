import jsPDF from "jspdf";
import "../../../fonts/roboto";
import { getCurrencyByCode, splitByNewLine } from "../../../utils/helpers";
import Url from "url-parse";

const config = {
  colorBlack: "#000000",
  colorGray: "#4d4e53",
  startingHeight: 15,

  headerTextSize: 15,
  labelTextSize: 12,
  fieldTextSize: 10,
  lineHeight: 6,
  subLineHeight: 4,

  header: ["#", "Description", "Quantity", "Price", "Amount"],
  // headerBorder: false,
  // tableBodyBorder: false,

  orientationLandscape: false,

  footerText:
    "The invoice is created on a computer and is valid without the signature and stamp.",
};

const splitTextAndGetHeight = (doc, text, size) => {
  var lines = doc.splitTextToSize(text, size);
  return {
    text: lines,
    height: doc.getTextDimensions(lines).h,
  };
};

const setBold = (doc) => {
  doc.setFont("Roboto", "bold");
};
const setNormal = (doc) => {
  doc.setFont("Roboto", "normal");
};

const render = (invoice, save) => {
  const doc = new jsPDF();

  setNormal(doc);

  var currentHeight = config.startingHeight;

  currentHeight = renderHeader(invoice, doc, currentHeight);

  renderDetails(invoice, doc, currentHeight);

  renderFooter(doc);

  if (save) {
    doc.save(`invoice ${invoice.number}.pdf`);
  } else {
    const dataUriString = doc.output("datauristring");

    return dataUriString;
  }
};

const renderHeader = (invoice, doc, currentHeight) => {
  currentHeight = renderBillFrom(invoice, doc, currentHeight);

  currentHeight += config.lineHeight;
  var docWidth = doc.internal.pageSize.width;
  doc.line(10, currentHeight, docWidth - 10, currentHeight);
  currentHeight += config.lineHeight * 2;

  currentHeight = renderBillTo(invoice, doc, currentHeight);
  currentHeight += config.lineHeight;

  return currentHeight;
};

const renderBillFrom = (invoice, doc, currentHeight) => {
  var docWidth = doc.internal.pageSize.width;

  const billFromLines = splitByNewLine(invoice.billFrom);

  let minimumHeight = config.lineHeight + config.subLineHeight * 3;

  if (billFromLines.length > 0) {
    setBold(doc);
    doc.setFontSize(config.headerTextSize);
    doc.setTextColor(config.colorBlack);
    doc.text(10, currentHeight, billFromLines[0]);
  }

  if (invoice.billFromLogo) {
    var imageHeader = new Image();
    imageHeader.src = invoice.billFromLogo;
    doc.addImage(imageHeader, docWidth / 2 - 10, currentHeight - 5);
  }

  doc.text(
    docWidth - 10,
    currentHeight,
    "Invoice #: " + invoice.number,
    "right"
  );

  currentHeight += config.lineHeight;
  minimumHeight -= config.lineHeight;

  setNormal(doc);
  doc.setFontSize(config.fieldTextSize);
  doc.setTextColor(config.colorGray);

  doc.text(
    docWidth - 10,
    currentHeight,
    `Invoice Date: ${invoice.date}`,
    "right"
  );

  if (billFromLines.length > 1) {
    doc.text(10, currentHeight, billFromLines[1]);
    currentHeight += config.subLineHeight;
    minimumHeight -= config.subLineHeight;
  }

  if (billFromLines.length > 2) {
    doc.text(10, currentHeight, billFromLines[2]);
    currentHeight += config.subLineHeight;
    minimumHeight -= config.subLineHeight;
  }
  if (billFromLines.length > 3) {
    doc.text(10, currentHeight, billFromLines[3]);
    currentHeight += config.subLineHeight;
    minimumHeight -= config.subLineHeight;
  }

  currentHeight += minimumHeight;

  return currentHeight;
};

const renderBillTo = (invoice, doc, currentHeight) => {
  let minimumHeight = config.lineHeight + config.subLineHeight * 3;

  const billToLines = splitByNewLine(invoice.billTo);

  if (invoice.billToLogo) {
    var imageHeader = new Image();

    imageHeader.src = invoice.billToLogo;
    doc.addImage(imageHeader, 12, currentHeight - 10);
  }

  setBold(doc);
  doc.setTextColor(config.colorGray);
  doc.setFontSize(config.labelTextSize);
  doc.text(40, currentHeight - config.lineHeight, "Bill To:");

  setBold(doc);
  doc.setTextColor(config.colorBlack);
  if (billToLines.length > 0) {
    doc.setFontSize(config.headerTextSize);
    doc.text(40, currentHeight, billToLines[0]);
  }

  currentHeight += config.lineHeight;
  minimumHeight -= config.lineHeight;

  setNormal(doc);
  doc.setTextColor(config.colorGray);
  doc.setFontSize(config.fieldTextSize);

  if (billToLines.length > 1) {
    doc.text(40, currentHeight, billToLines[1]);
    currentHeight += config.subLineHeight;
    minimumHeight -= config.subLineHeight;
  }

  if (billToLines.length > 2) {
    doc.text(40, currentHeight, billToLines[2]);
    currentHeight += config.subLineHeight;
    minimumHeight -= config.subLineHeight;
  }

  if (billToLines.length > 3) {
    doc.text(40, currentHeight, billToLines[3]);
    currentHeight += config.subLineHeight;
    minimumHeight -= config.subLineHeight;
  }

  currentHeight += minimumHeight;

  return currentHeight;
};

const renderDetails = (invoice, doc, currentHeight) => {
  var docWidth = doc.internal.pageSize.width;
  // var tdWidth = (doc.getPageWidth() - 20) / config.header.length;

  // var addTableHeaderBorder = () => {
  //   currentHeight += 2;
  //   for (let i = 0; i < config.header.length; i++) {
  //     if (i === 0) doc.rect(10, currentHeight, tdWidth, 7);
  //     else doc.rect(tdWidth * i + 10, currentHeight, tdWidth, 7);
  //   }
  //   currentHeight -= 2;
  // };
  // var addTableBodyBorder = (lineHeight) => {
  //   for (let i = 0; i < config.header.length; i++) {
  //     if (i === 0) doc.rect(10, currentHeight, tdWidth, lineHeight);
  //     else doc.rect(tdWidth * i + 10, currentHeight, tdWidth, lineHeight);
  //   }
  // };
  var addTableHeader = () => {
    // if (config.headerBorder) {
    //   addTableHeaderBorder();
    // }

    doc.setTextColor(config.colorBlack);
    doc.setFontSize(config.fieldTextSize);
    doc.setDrawColor(config.colorGray);
    currentHeight += 2;

    config.header.forEach(function (row, index) {
      if (index === 0) {
        doc.text(row, 11, currentHeight);
      } else {
        if (index === 1) {
          doc.text(row, 20, currentHeight);
        } else if (index === 2) {
          doc.text(row, docWidth - 90, currentHeight, "right");
        } else if (index === 3) {
          doc.text(row, docWidth - 50, currentHeight, "right");
        } else if (index === 4) {
          doc.text(row, docWidth - 10, currentHeight, "right");
        } else {
          alert("implement a new column");
        }
      }
    });

    currentHeight += config.subLineHeight - 1;
    doc.setTextColor(config.colorGray);
  };
  addTableHeader();

  var tableBodyLength = invoice.items.length;
  invoice.items.forEach(function (item, index) {
    doc.line(10, currentHeight, docWidth - 10, currentHeight);

    var row = [
      index + 1,
      item.description,
      item.quantity.toFixed(3).toString(),
      getCurrencyByCode(invoice.currency)?.symbol +
        item.price.toFixed(2).toString(),
      getCurrencyByCode(invoice.currency)?.symbol +
        item.amount.toFixed(2).toString(),
    ];

    let rowsHeight = [];
    var getRowsHeight = function () {
      row.forEach(function (rr, index) {
        let item;
        if (index === 0) {
          item = splitTextAndGetHeight(doc, rr.toString(), 20 - 1);
        } else if (index === 1) {
          item = splitTextAndGetHeight(
            doc,
            rr.toString(),
            docWidth - 90 - 20 - 1
          );
        } else if (index === 2) {
          item = splitTextAndGetHeight(doc, rr.toString(), 40 - 1);
          // doc.text(row, docWidth - 90, currentHeight, "right");
        } else if (index === 3) {
          item = splitTextAndGetHeight(doc, rr.toString(), 40 - 1);
          // doc.text(row, docWidth - 50, currentHeight, "right");
        } else if (index === 4) {
          item = splitTextAndGetHeight(doc, rr.toString(), 40 - 1);
          // doc.text(row, docWidth - 10, currentHeight, "right");
        } else {
          alert("implement a new column");
        }
        // let item = splitTextAndGetHeight(doc, rr.toString(), tdWidth - 1);
        rowsHeight.push(item.height);
      });
    };
    getRowsHeight();

    var maxHeight = Math.max(...rowsHeight);

    // if (config.tableBodyBorder) {
    //   addTableBodyBorder(maxHeight + 1);
    // }

    row.forEach(function (rr, index) {
      let item;
      if (index === 0) {
        item = splitTextAndGetHeight(doc, rr.toString(), 20 - 1);
      } else if (index === 1) {
        item = splitTextAndGetHeight(
          doc,
          rr.toString(),
          docWidth - 90 - 20 - 1
        );
      } else if (index === 2) {
        item = splitTextAndGetHeight(doc, rr.toString(), 40 - 1);
      } else if (index === 3) {
        item = splitTextAndGetHeight(doc, rr.toString(), 40 - 1);
      } else if (index === 4) {
        item = splitTextAndGetHeight(doc, rr.toString(), 40 - 1);
      } else {
        alert("implement a new column");
      }

      // let item = splitTextAndGetHeight(doc, rr.toString(), tdWidth - 1);

      if (index === 0) {
        doc.text(item.text, 11, currentHeight + 4);
      } else {
        if (index === 1) {
          doc.text(item.text, 20, currentHeight + 4);
        } else if (index === 2) {
          doc.text(item.text, docWidth - 90, currentHeight + 4, "right");
        } else if (index === 3) {
          doc.text(item.text, docWidth - 50, currentHeight + 4, "right");
        } else if (index === 4) {
          doc.text(item.text, docWidth - 10, currentHeight + 4, "right");
        } else {
          alert("implement a new column");
        }
      }

      // if (index === 0) {
      //   doc.text(item.text, 11, currentHeight + 4);
      // } else {
      //   doc.text(item.text, 11 + index * tdWidth, currentHeight + 4);
      // }
    });

    currentHeight += maxHeight - 4;

    currentHeight += 5;

    if (index + 1 < tableBodyLength) {
      currentHeight += maxHeight;
    }

    if (
      config.orientationLandscape &&
      (currentHeight > 185 ||
        (currentHeight > 178 && doc.getNumberOfPages() > 1))
    ) {
      doc.addPage();
      currentHeight = 10;
      if (index + 1 < tableBodyLength) addTableHeader();
    }

    if (
      !config.orientationLandscape &&
      (currentHeight > 265 ||
        (currentHeight > 255 && doc.getNumberOfPages() > 1))
    ) {
      doc.addPage();
      currentHeight = 10;
      if (index + 1 < tableBodyLength) {
        addTableHeader();
      }
    }

    if (index + 1 < tableBodyLength && currentHeight > 30)
      currentHeight -= maxHeight;
  });

  //   setBold(doc);
  doc.setFontSize(config.headerTextSize);
  doc.setTextColor(config.colorBlack);
  currentHeight += config.lineHeight;

  doc.line(docWidth - 50, currentHeight, docWidth - 10, currentHeight);
  currentHeight += config.lineHeight;

  doc.text(
    docWidth - 10,
    currentHeight,
    `Total: ${getCurrencyByCode(invoice.currency)?.symbol}${invoice.total
      .toFixed(2)
      .toString()}`,
    "right"
  );

  currentHeight += config.lineHeight;

  setNormal(doc);

  // This should be note, add for terms also
  if (invoice.note) {
    doc.setFontSize(config.labelTextSize);
    doc.setTextColor(config.colorBlack);

    doc.text("Note:", 10, currentHeight);
    currentHeight += config.subLineHeight;
    doc.setTextColor(config.colorGray);
    doc.setFontSize(config.fieldTextSize - 1);

    var lines = doc.splitTextToSize(invoice.note, docWidth / 2);
    //text in left half
    doc.text(lines, 10, currentHeight);
    currentHeight +=
      doc.getTextDimensions(lines).h > 5
        ? doc.getTextDimensions(lines).h + 6
        : config.lineHeight;
  }

  return currentHeight;
};

const renderFooter = (doc) => {
  const url = new Url(window.location.href);

  var docWidth = doc.internal.pageSize.width;
  var docHeight = doc.internal.pageSize.height;

  for (let i = 1; i <= doc.getNumberOfPages(); i++) {
    doc.setFontSize(config.fieldTextSize - 2);
    doc.setTextColor(config.colorGray);

    doc.text(
      docWidth / 2,
      docHeight - (config.lineHeight - 2) - 10,
      config.footerText,
      "center"
    );
    doc.text(
      docWidth / 2,
      docHeight - 10,
      `This invoice is generated by ${url.origin}`, // https://invoice-book.sursumcorda.com
      "center"
    );
    doc.setPage(i);
    doc.text(
      "Page " + i + " / " + doc.getNumberOfPages(),
      docWidth - 20,
      doc.internal.pageSize.height - 6
    );
  }
};

export { render, renderHeader, renderFooter };
