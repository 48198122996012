import { Form, Button, Row, Col, Input } from "antd";
import { useLocation, useNavigate } from "react-router";
import { db } from "../../../db";
import PropTypes from "prop-types";
import moment from "moment";

import InvoiceDetailsForm from "./InvoiceDetailsForm";
import InvoiceFooterForm from "./InvoiceFooterForm";
import InvoiceHeaderForm from "./InvoiceHeaderForm";

import { dateFormat } from "../../../utils/helpers";

export default function InvoiceForm({ invoice }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();

  const initialValues = invoice
    ? { ...invoice, date: moment(invoice.date, dateFormat) }
    : {
        date: moment(),
        // subtotal: 0,
        // discount: 0,
        // tax: 0,
        total: 0,
        currency: "USD",
        items: [{ description: null, quantity: 1, price: 1, amount: 1 }],
      };

  const onClickCancel = (e) => {
    e.stopPropagation();

    if (
      location.state &&
      location.state.prevPath &&
      location.state.prevPath.startsWith("/invoices/")
    ) {
      navigate("/invoices");
    } else {
      navigate(-1);
    }
  };

  const onFinish = (values) => {
    const data = {
      number: values.number,
      date: values.date.format("YYYY-MM-DD"),
      billFrom: values.billFrom,
      billFromLogo: values.billFromLogo,
      billTo: values.billTo,
      // shipTo: values.shipTo,
      billToLogo: values.billToLogo,
      note: values.note,
      // terms: values.terms,
      // subtotal: values.subtotal,
      // discount: values.discount,
      // tax: values.tax,
      // shipping: values.shipping,
      total: values.total,
      // amountPaid: values.amountPaid,
      // balanceDue: values.balanceDue,
      currency: values.currency,

      items: values.items,
    };

    const { id } = values;

    if (id) {
      db.invoices
        .update(id, data)
        .then((res) => {
          navigate(`/invoices/${id}/open`);
        })
        .catch((e) => {
          console.log("e", e);
        });
    } else {
      db.invoices
        .add(data)
        .then((res) => {
          navigate(`/invoices/${Number(res)}/open`);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeValue = (item) => {
    let values = form.getFieldsValue(true);

    const subtotal = values.items.reduce(
      (accumulator, current) => accumulator + current.amount,
      0
    );

    values.subtotal = subtotal;
    const tax = values.tax ?? 0;
    const shipping = values.shipping ?? 0;

    values.total = tax + shipping + subtotal;

    form.setFieldsValue(values);
  };

  return (
    <Form
      form={form}
      name="newInvoice"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item hidden name="id">
        <Input></Input>
      </Form.Item>

      <InvoiceHeaderForm />

      <InvoiceDetailsForm form={form} onChangeValue={onChangeValue} />

      <InvoiceFooterForm />

      <Row gutter={20} justify="space-around">
        <Col>
          <Button onClick={onClickCancel}>Cancel</Button>
        </Col>
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

InvoiceForm.propTypes = {
  invoice: PropTypes.object,
};
