import { Typography } from "antd";
import InvoiceForm from "./forms/InvoiceForm";

export default function NewInvoice() {

  return (
    <>
      <Typography.Title style={{ textAlign: "center" }}>
        New invoice
      </Typography.Title>

      <InvoiceForm />
    </>
  );
}
