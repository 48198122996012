import PropTypes from "prop-types";
import { Typography, List } from "antd";
import { useNavigate } from "react-router-dom";
import InvoiceActions from "./InvoiceActions";
import { getBillToName, getCurrencyByCode } from "../../../utils/helpers";

export default function InvoiceList({ data }) {
  const navigate = useNavigate();

  if (!data || data.length === 0) {
    return <div style={{ textAlign: "center", marginBottom: 20 }}>No Data</div>;
  }

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 700,
        overflow: "auto",
        padding: "0 16px",
        border: "1px solid rgba(140, 140, 140, 0.35)",
        marginBottom: 30,
      }}
    >
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            style={{ cursor: "pointer" }}
            onClick={(e) => navigate(`/invoices/${item.id}/open`)}
            actions={[<InvoiceActions invoice={item} />]}
          >
            <List.Item.Meta
              title={
                <Typography.Text>
                  <strong>
                    {item.number} - {getBillToName(item)}
                  </strong>
                </Typography.Text>
              }
              description={
                <Typography.Text>
                  {item.date} - {getCurrencyByCode(item.currency)?.symbol}
                  {item.total.toFixed(2).toString()}
                </Typography.Text>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
}

InvoiceList.propTypes = {
  data: PropTypes.array.isRequired,
};
