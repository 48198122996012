import "./Header.css";
import React from "react";
import { Col, Layout, Row, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;
const { Header } = Layout;

export default function App() {
  return (
    <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
      <Row justify="space-between">
        <Col>
          <Link to="/">
            <img className="logo" src="/logo.png" alt="Snail" />
          </Link>
          <Text style={{ color: "whitesmoke" }}>SRG Invoice Book</Text>
        </Col>
        <Text style={{ color: "whitesmoke" }}>
          Support: vladimirc@sursumcorda.com
        </Text>
      </Row>
    </Header>
  );
}
