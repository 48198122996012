import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function NewInvoiceButton() {
  const navigate = useNavigate();

  return (
    <Button type="primary" onClick={() => navigate("/invoices/new")}>
      Add New Invoice
    </Button>
  );
}
