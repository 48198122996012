import { useEffect, useState } from "react";
import { useParams } from "react-router";
import InvoiceActions from "./components/InvoiceActions";
import { db } from "../../db";
import { Space, Typography, Tooltip } from "antd";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { render } from "./pdf/pdfRender";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

const iconSize = "150%";

export default function OpenInvoice() {
  const params = useParams();

  const [invoice, setInvoice] = useState(null);
  const [pdfDataUri, setPdfDataUri] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  useEffect(() => {
    if (invoice == null) {
      db.invoices
        .get(Number(params.invoiceId))
        .then((res) => {
          setInvoice(res);

          const dataUriString = render(res, false);
          setPdfDataUri(dataUriString);
        })
        .catch((e) => {
          setInvoice({});
        });
    }
  });

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function Arrows(props) {
    if (numPages <= 1) return null;

    return (
      <Space
        style={{ width: "100%", justifyContent: "center" }}
        size={20}
        direction="horizontal"
      >
        {pageNumber > 1 && (
          <Tooltip title="Previous">
            <LeftOutlined
              onClick={() => changePage(-1)}
              style={{ fontSize: iconSize, color: blue.primary }}
            />
          </Tooltip>
        )}

        {pageNumber < numPages && (
          <Tooltip title="Next">
            <RightOutlined
              onClick={() => changePage(1)}
              style={{ fontSize: iconSize, color: blue.primary }}
            />
          </Tooltip>
        )}
      </Space>
    );
  }

  return (
    <>
      {invoice && (
        <Typography.Title style={{ textAlign: "center" }}>
          Invoice {invoice.number}
        </Typography.Title>
      )}

      <Space
        style={{ width: "100%", justifyContent: "center", marginBottom: 20 }}
        direction="horizontal"
      >
        {invoice && <InvoiceActions invoice={invoice} />}
      </Space>

      <Arrows />

      <Space
        style={{ width: "100%", justifyContent: "center" }}
        direction="horizontal"
      >
        {pdfDataUri && (
          <Document
            file={pdfDataUri}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            loading="Loading Invoice"
          >
            <Page width={1200} pageNumber={pageNumber} />
          </Document>
        )}
      </Space>

      <Arrows />
    </>
  );
}
