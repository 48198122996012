import { Col, Row, Form, Input, InputNumber, Space, Typography, Select } from "antd";
import currencies from "../../../data/currencies";

const { Option } = Select;

const rowGutter = 20;

export default function InvoiceFooterForm() {
  return (
    <Row gutter={rowGutter} justify="space-between">
      <Col md={12}>
        <Row>
          <Col md={24}>
            <Form.Item label="Note (optional)" name="note">
              <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
            </Form.Item>
          </Col>
          {/* <Col md={24}>
            <Form.Item label="Terms (optional)" name="terms">
              <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
            </Form.Item>
          </Col> */}
        </Row>
      </Col>

      <Col md={6}>
        {/* <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Subtotal</Typography.Text>
              <Form.Item name="subtotal" shouldUpdate noStyle>
                <InputNumber
                  addonBefore="$"
                  controls={false}
                  precision={2}
                  disabled={true}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row> */}
        {/* <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Discount</Typography.Text>
              <Form.Item name="discount" noStyle>
                <InputNumber addonBefore="%" controls={false} precision={2} />
              </Form.Item>
            </Space>
          </Col>
        </Row> */}
        {/* <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Tax</Typography.Text>
              <Form.Item name="tax" noStyle>
                <InputNumber addonBefore="%" controls={false} precision={2} />
              </Form.Item>
            </Space>
          </Col>
        </Row> */}
        {/* <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Shipping</Typography.Text>
              <Form.Item name="shipping" noStyle>
                <InputNumber addonBefore="$" controls={false} precision={2} />
              </Form.Item>
            </Space>
          </Col>
        </Row> */}
        <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Total</Typography.Text>
              <Form.Item name="total" shouldUpdate noStyle>
                <InputNumber
                  addonBefore="$"
                  controls={false}
                  precision={2}
                  disabled={true}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Currency</Typography.Text>
              <Form.Item name="currency" noStyle>
                {/* <Currency /> */}
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Search Currencies"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // filterSort={(optionA, optionB) =>
                  //   optionA.children
                  //     .toLowerCase()
                  //     .localeCompare(optionB.children.toLowerCase())
                  // }
                >
                  {currencies.map((element, index) => (
                    <Option key={element.code} value={element.code}>
                      {element.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
          </Col>
        </Row>
        {/* <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Amount Paid</Typography.Text>
              <Form.Item name="paid" noStyle>
                <InputNumber addonBefore="$" controls={false} precision={2} />
              </Form.Item>
            </Space>
          </Col>
        </Row> */}
        {/* <Row gutter={rowGutter} style={{ marginBottom: 10 }} justify="end">
          <Col>
            <Space>
              <Typography.Text>Balance Due</Typography.Text>
              <Form.Item name="balanceDue" shouldUpdate noStyle>
                <InputNumber
                  addonBefore="$"
                  controls={false}
                  precision={2}
                  disabled={true}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row> */}
      </Col>
    </Row>
  );
}
