import { useState } from "react";
import { Upload, Space } from "antd";

import {
  MinusOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default function ImageUpload(props) {
  const [loading, setLoading] = useState(false);

  const handleChange = (info) => {
    setLoading(true);

    getBase64(info.fileList[0].originFileObj, (fileUrl) => {
      props.onChange(fileUrl);
      setLoading(false);
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Space>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={() => false}
        onChange={handleChange}
      >
        {props.value ? (
          <img src={props.value} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
      {props.value && <MinusOutlined onClick={() => props.onChange(null)} />}
    </Space>
  );
}
