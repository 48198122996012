import currencies from "../data/currencies";

const dateFormat = "YYYY-MM-DD";

const getBillToName = (invoice) => {
  var lines = splitByNewLine(invoice.billTo);
  if (lines.length > 0) {
    return lines[0];
  }

  return null;
};

const getCurrencyByCode = (code) => {
  if (code){
    const element = currencies.find(
      (element) => element.code.toLowerCase() === code.toLowerCase()
    );

    return element;
  }
  
  return null;
};

const splitByNewLine = (stringValue) => {
  return stringValue.split(/\r?\n/);
};

export { dateFormat, getBillToName, getCurrencyByCode, splitByNewLine };
