import * as robotoRegular from "./roboto/RobotoRegular";
import * as robotoBold from "./roboto/RobotoBold";
import * as robotoItalic from "./roboto/RobotoItalic";
import * as robotoBoldItalic from "./roboto/RobotoBoldItalic";

const font = {
    robotoRegular,
    robotoBold,
    robotoItalic,
    robotoBoldItalic,
};

export default font;
