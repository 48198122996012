import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../db";

import { Input, Space, Typography } from "antd";

import InvoiceList from "./components/InvoiceList";
import NewInvoiceButton from "../invoices/components/NewInvoiceButton";
import { useState } from "react";

const { Title } = Typography;

export default function Invoices() {
  const [search, setSearch] = useState(null);

  const invoices = useLiveQuery(
    () =>
      db.invoices
        .filter((element) => {
          if (search && search !== "") {
            if (
              element?.billTo.toLowerCase().indexOf(search.toLowerCase()) !== -1
            ) {
              return true;
            }

            if (
              element?.date.toLowerCase().indexOf(search.toLowerCase()) !== -1
            ) {
              return true;
            }

            if (
              element?.total.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
            ) {
              return true;
            }

            return false;
          }

          return true;
        })
        .toArray(),
    [search]
  );

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Title style={{ textAlign: "center" }} level={2}>
        Invoices
      </Title>

      <Space
        style={{ width: "100%", justifyContent: "center", marginBottom: 20 }}
        direction="horizontal"
      >
        <Input placeholder="Search" allowClear onChange={onChange} />
        <NewInvoiceButton />
      </Space>

      {invoices && <InvoiceList data={invoices} />}
    </>
  );
}
