import { Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { db } from "../../db";

import InvoiceForm from "./forms/InvoiceForm";

export default function EditInvoice() {
  const params = useParams();
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    if (invoice == null) {
      db.invoices
        .get(Number(params.invoiceId))
        .then((res) => {
          setInvoice(res);
        })
        .catch((e) => {
          setInvoice({});
        });
    }
  });

  return (
    <>
      {invoice && (
        <Typography.Title style={{ textAlign: "center" }}>
          Edit Invoice
        </Typography.Title>
      )}

      {invoice && <InvoiceForm invoice={invoice} />}
    </>
  );
}
