import "./InvoiceHeaderForm.css";
import { Col, Row, Form, Input, DatePicker, InputNumber } from "antd";
import ImageUpload from "../../../components/ImageUpload";

export default function InvoiceHeaderForm() {
  return (
    <Row>
      <Col xs={24} md={3}>
        <Form.Item
          name="number"
          label="Invoice Number"
          rules={[
            {
              required: true,
              message: "Invoice Number is required",
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            addonBefore="#"
            placeholder="Invoice Number"
            controls={false}
            precision={0}
          />
        </Form.Item>
        <Form.Item
          label="Invoice Date (required)"
          name="date"
          // getValueFromEvent={getDate}
          rules={[
            {
              required: true,
              message: "Date",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col xs={24} md={{ offset: 6, span: 15 }}>
        <Row justify="space-between">
          <Col xs={24} md={7}>
            <Form.Item
              label="Sender Information (required)"
              name="billFrom"
              rules={[
                {
                  required: true,
                  message: "Please enter sender information",
                },
              ]}
            >
              <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={7}>
            <Form.Item label="Sender Logo">
              <Form.Item name="billFromLogo" noStyle>
                <ImageUpload />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={24} md={7}>
            <Form.Item
              label="Billing Information (required)"
              name="billTo"
              rules={[
                {
                  required: true,
                  message: "Please enter billing information",
                },
              ]}
            >
              <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={7}>
            {/* <Form.Item label="Shipping Information (optional)" name="shipTo">
              <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
            </Form.Item> */}
          </Col>
          <Col xs={24} md={7}>
            <Form.Item label="Biller Logo">
              <Form.Item name="billToLogo" noStyle>
                <ImageUpload />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
