import { Space, Tooltip } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { render } from "../pdf/pdfRender";
import { dateFormat } from "../../../utils/helpers";
import moment from "moment";
import { db } from "../../../db";
import {
  DownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { blue, red } from "@ant-design/colors";

const iconSize = "150%";

function InvoiceActions({ invoice }) {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickEdit = (e, invoice) => {
    e.stopPropagation();

    navigate(`/invoices/${invoice.id}/edit`);
  };

  const onClickClone = (e, invoice) => {
    e.stopPropagation();

    const { id, ...newInvoice } = invoice;
    newInvoice.number = invoice.number + 1;
    newInvoice.date = moment().format(dateFormat);
    if (invoice.note) {
      newInvoice.note = invoice.note;
    }

    db.invoices
      .add(newInvoice)
      .then((res) => {
        navigate(`/invoices/${Number(res)}/edit`);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const onClickDelete = (e, invoice) => {
    e.stopPropagation();

    navigate(`/invoices/${invoice.id}/delete`, {
      state: {
        prevPath: location.pathname,
      },
    });
  };

  const onClickDownload = (e, invoice) => {
    e.stopPropagation();

    render(invoice, true);
  };

  return (
    <>
      <Space size={20}>
        <Tooltip title="Edit">
          <EditOutlined
            onClick={(e) => onClickEdit(e, invoice)}
            style={{ fontSize: iconSize, color: blue.primary }}
          />
        </Tooltip>
        <Tooltip title="Clone">
          <CopyOutlined
            onClick={(e) => onClickClone(e, invoice)}
            style={{ fontSize: iconSize }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteOutlined
            onClick={(e) => onClickDelete(e, invoice)}
            style={{ fontSize: iconSize, color: red.primary }}
          />
        </Tooltip>
        <Tooltip title="Download">
          <DownloadOutlined
            onClick={(e) => onClickDownload(e, invoice)}
            style={{ fontSize: iconSize }}
          />
        </Tooltip>
      </Space>
    </>
  );
}

InvoiceActions.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default InvoiceActions;
